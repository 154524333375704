
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function MainContentRT () {
    return _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, '\n  ', Convermax.t('These parts will not fit your'), ' ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n  ', Convermax.t('Parts shown below are based on keyword search only'), '.\n')] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '8020'
                }, '\n  ', Convermax.t('No vehicle specific products found for'), '\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  ', Convermax.t('Press'), '\n  ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, Convermax.t('here')), '\n  ', Convermax.t('to view parts that fit this vehicle.'), '\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1279'
            }, _createElement('div', {}, '\n  ', Convermax.t('We failed to process your request'), ' (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ', ', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n  ', _createElement('br', {}), '\n  ', Convermax.t('You may'), '\n  ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, Convermax.t('clear your search')), '\n  ', Convermax.t('and try to search for something else'), '.\n')) : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1828'
            }, _createElement('div', {}, '\n  ', Convermax.t('Your search for'), '\n  ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\n  ', Convermax.t('did not match any of the products we carry. We searched for'), '\n  ', _createElement('span', { 'className': 'query' }, this.query), ' ', Convermax.t('instead'), '.\n')) : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2192'
            }, _createElement('div', {}, '\n  ', Convermax.t('Network error'), '\n  ', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n  ', _createElement('br', {}), '\n  ', Convermax.t('We could not reach the search server.'), '\n')) : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2559'
            }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                '\n    ',
                Convermax.t(`This ${ this.pageType } doesn't have any`),
                '\n    ',
                !!this.vehicleString ? [
                    '\n      ',
                    Convermax.t('parts that fit'),
                    ' ',
                    _createElement('span', { 'key': '28111' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString ? [' products. '] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30541' }, Convermax.t('No results were found for your search'), '.')] : null, this.query || !!this.vehicleString ? [
                '\n    ',
                Convermax.t('Press'),
                '\n    ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '32121'
                }, _createElement('b', {}, Convermax.t('here'))),
                '\n    ',
                Convermax.t('to clear'),
                '\n    ',
                !this.vehicleString ? [
                    ' ',
                    Convermax.t('selection'),
                    '. '
                ] : null,
                this.vehicleString && !this.isFacetsSelected ? [
                    '\n      ',
                    Convermax.t('vehicle selection and browse all parts'),
                    '.\n    '
                ] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3755'
            }, _createElement('div', {}, '\n  ', Convermax.t('The request took too long. Please'), '\n  ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, Convermax.t('try your search again')), '.\n')) : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '4035'
            }, _createElement('div', {}, '\n  ', Convermax.t('We were unable to find products that match entire query'), '\n  ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. ', Convermax.t('We performed a partial search instead.'), '\n')) : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4335'
            }, _createElement('div', {}, '\n  ', Convermax.t('We failed to process your request'), this.error ? [
                '\n    (',
                this.error.status ? [
                    _createElement('b', { 'key': '45180' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '46050' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '46880' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '44814' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n  ', _createElement('br', {}), '\n  ', Convermax.t('Please'), '\n  ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, Convermax.t('try your search again')), '.\n')) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.AutoSyncVisualization(function () {
            return _createElement('div', { 'className': 'cm_AutoSyncVisualization' }, _createElement('div', { 'className': 'cm_vehicle-visualization-container' }, this.visualization));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, entryIndex) {
                            return _createElement('div', {
                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3194'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3396'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled
                        }, this.useNativeDropdown ? [
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n          ', '', Convermax.t(this.title) || this.title, '\n        ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '7541'
                                }, 'Popular Makes'),
                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '7544'
                                }, 'All Makes'),
                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                            ] : null,
                            this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                        ] : null, !this.useNativeDropdown ? _createElement('option', {
                            'key': '_current',
                            'value': '_current'
                        }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'key': '2558'
                            },
                            !this.hideNullOption ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'onClick': () => this.onChange('')
                            }, '\n      ', '', Convermax.t(this.title) || this.title, '\n    ') : null,
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, Convermax.t('...loading...')) : null,
                            !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                        ]) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search cm_SearchVehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '80'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, this.isVehicleSelected ? Convermax.t('Your vehicle') : Convermax.t('Select your vehicle')), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    ', Convermax.t('GO'), '\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, '\n    ', Convermax.t('Clear'), '\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_locked',
                'key': '6482'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, this.isVehicleSelected ? Convermax.t('Your vehicle') : Convermax.t('Select your vehicle')), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_label' }, '\n      ', this.vehicleString, '\n      ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle)), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button',
                'onClick': this.changeVehicle
            }, '\n        ', Convermax.t('Change'), '\n      '), _createElement('div', {
                'className': 'cm_vehicle-widget_button',
                'onClick': this.discardVehicle
            }, '\n        ', Convermax.t('Clear'), '\n      ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, _createElement('div', { 'className': 'cm_total-hits' }, _createElement('span', { 'className': 'hits' }, this.pageFrom, '-', this.pageTo), ' ', Convermax.t('of'), ' ', this.totalHits, '\n', Convermax.t('Results'), '\n'), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', {}, Convermax.t('Sorting'), ':'), '\n  ', this.sortSelect, '\n'), [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_dialogButton form-field selection' + (this.selectedFacetsCount ? ' selected' : '') }, '\n  ', Convermax.t('Filters'), ' ', this.selectedFacetsCount ? _createElement('span', {
                                'className': 'cm_dialogButton__count',
                                'key': '153'
                            }, ' ', this.selectedFacetsCount) : null, this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'width': '17',
                                    'height': '18',
                                    'viewBox': '0 0 17 18',
                                    'fill': 'none',
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'key': '2630'
                                }, _createElement('path', {
                                    'd': 'M8 17.5V12.5H9V14.5H17V15.5H9V17.5H8ZM0 15.5V14.5H5V15.5H0ZM4 11.5V9.5H0V8.5H4V6.5H5V11.5H4ZM8 9.5V8.5H17V9.5H8ZM12 5.5V0.5H13V2.5H17V3.5H13V5.5H12ZM0 3.5V2.5H9V3.5H0Z',
                                    'fill': 'black'
                                }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'width': '17',
                                    'height': '18',
                                    'viewBox': '0 0 17 18',
                                    'fill': 'none',
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'key': '6490'
                                }, _createElement('path', {
                                    'd': 'M8 17.5V12.5H9V14.5H17V15.5H9V17.5H8ZM0 15.5V14.5H5V15.5H0ZM4 11.5V9.5H0V8.5H4V6.5H5V11.5H4ZM8 9.5V8.5H17V9.5H8ZM12 5.5V0.5H13V2.5H17V3.5H13V5.5H12ZM0 3.5V2.5H9V3.5H0Z',
                                    'fill': 'black'
                                }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function scopeWishlist1(rawWishlist, rawWishlistIndex) {
                            var wishlist = rawWishlist.split('|');
                            return _createElement('input', {
                                'className': 'button button--primary',
                                'formaction': '/wishlist.php?action=add&wishlistid=' + wishlist[0] + '&product_id=' + this.id,
                                'type': 'submit',
                                'value': wishlist[1]
                            });
                        }
                        function repeatRawWishlist2(rawWishlist, rawWishlistIndex) {
                            return [_createElement('li', { 'key': '11371' }, scopeWishlist1.apply(this, [
                                    rawWishlist,
                                    rawWishlistIndex
                                ]))];
                        }
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        function repeatI4(i, iIndex) {
                            return _createElement('span', {
                                'key': i,
                                'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                            });
                        }
                        return _createElement('li', { 'className': 'product' }, _createElement('article', { 'className': 'card' }, _createElement('figure', { 'className': 'card-figure' }, _createElement('form', {
                            'action': '/wishlist.php?action=add&product_id=' + this.id,
                            'className': 'form form-wishlist form-action',
                            'method': 'post'
                        }, _createElement('a', {
                            'onClick': e => window.Convermax.handleWishlistDropdown(e),
                            'className': 'button button--primary dropdown-menu-button',
                            'aria-controls': 'wishlist-dropdown',
                            'aria-expanded': 'true',
                            'data-dropdown': 'wishlist-dropdown'
                        }, _createElement('i', { 'className': 'material-icons' }, ' favorite_outline ')), !window.Convermax.customerWishlists?.length ? _createElement('ul', {
                            'aria-hidden': 'false',
                            'className': 'dropdown-menu f-open-dropdown',
                            'key': '502'
                        }, _createElement('li', {}, _createElement('button', {
                            'className': 'button button--primary',
                            'type': 'submit'
                        }, Convermax.t('Add to my wish list'))), _createElement('li', {}, _createElement('a', {
                            'className': 'button button--primary',
                            'href': '/wishlist.php?action=addwishlist&product_id=' + this.id
                        }, '\n              ', Convermax.t('Create new wish list'), '\n            '))) : null, window.Convermax.customerWishlists?.length ? _createElement.apply(this, [
                            'ul',
                            {
                                'aria-hidden': 'false',
                                'className': 'dropdown-menu f-open-dropdown',
                                'key': '1013'
                            },
                            _map(window.Convermax.customerWishlists, repeatRawWishlist2.bind(this)),
                            _createElement('li', {}, _createElement('a', {
                                'className': 'button button--primary',
                                'href': '/wishlist.php?action=addwishlist&product_id=' + this.id
                            }, '\n              ', Convermax.t('Create new wish list'), '\n            '))
                        ]) : null), _createElement('a', {
                            'className': 'card-figure__link',
                            'href': this.custom_url
                        }, _createElement('div', { 'className': 'badges-container badges-container--left' }, this.on_sale ? _createElement('div', {
                            'className': 'custom-badge',
                            'key': '1892'
                        }, 'Sale %') : null, this.stock?.includes('In-stock Only') ? _createElement('div', {
                            'className': 'custom-badge custom-badge--success',
                            'key': '1962'
                        }, '\n            ', Convermax.t('In Stock'), '\n          ') : null, this['pdm:tuv_information'] ? _createElement('div', {
                            'className': 'custom-badge custom-badge--secondary',
                            'key': '2142'
                        }, '\n            ', Convermax.t('Tüv'), '\n          ') : null, this.out_of_stock ? _createElement('div', {
                            'className': 'custom-badge custom-badge--tertiary',
                            'key': '2314'
                        }, '\n            ', Convermax.t('Sold out'), '\n          ') : null, this.badge_one_left_text && this.badge_one_left_color && this.inv_qty_avail ? _createElement('div', {
                            'className': 'custom-badge custom-badge--' + this.badge_one_left_color.toLowerCase(),
                            'key': '2465'
                        }, '\n            ', this.badge_one_left_text, '\n          ') : null, this.badge_two_left_text && this.badge_two_left_color ? _createElement('div', {
                            'className': 'custom-badge custom-badge--' + this.badge_two_left_color.toLowerCase(),
                            'key': '2716'
                        }, '\n            ', this.badge_two_left_text, '\n          ') : null, this.badge_three_left_text && this.badge_three_left_color ? _createElement('div', {
                            'className': 'custom-badge custom-badge--' + this.badge_three_left_color.toLowerCase(),
                            'key': '2937'
                        }, '\n            ', this.badge_three_left_text, '\n          ') : null), _createElement('div', { 'className': 'badges-container badges-container--right' }, this.badge_one_right_text && this.badge_one_right_color ? _createElement('div', {
                            'className': 'custom-badge custom-badge--' + this.badge_one_right_color.toLowerCase(),
                            'key': '3244'
                        }, '\n            ', this.badge_one_right_text, '\n          ') : null), _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                            'className': 'card-image lazyautosizes lazyloaded',
                            'src': this.imageOrDefault(this.images_image_thumbnail1 || this.images_image_thumbnail2),
                            'alt': this.removeHTML(this.name),
                            'title': this.removeHTML(this.name),
                            'onError': this.onImageError
                        })))), _createElement('div', { 'className': 'card-body' }, _createElement('div', { 'className': 'card-body-top' }, _createElement('div', { 'className': 'card-carInfo' }, this['pdm:year_range'] ? _createElement('p', {
                            'className': 'card-text',
                            'data-test-info-type': 'carInfo',
                            'key': '3915'
                        }, '\n            ', this['pdm:year_range'], '\n          ') : null), _createElement('h4', { 'className': 'card-title' }, _createElement('a', Object.assign({}, { 'href': this.custom_url }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('p', mergeProps({
                            'className': 'card-text card-brand',
                            'data-test-info-type': 'brandName'
                        }, { dangerouslySetInnerHTML: { __html: this.brand_name } })), _createElement('div', { 'className': 'card-reviews' }, [this.review_count ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_review-stars',
                                    'key': '44140'
                                },
                                _map([
                                    0,
                                    1,
                                    2,
                                    3,
                                    4
                                ], repeatI4.bind(this)),
                                _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                            ]) : null])), _createElement('div', { 'className': 'card-body-bottom' }, _createElement('div', {
                            'className': 'card-text',
                            'data-test-info-type': 'price'
                        }, _createElement('div', { 'className': 'price-section price-section--withTax' }, _createElement('h3', { 'className': 'price price--withTax' }, this.formatPrice(this.price))), this.on_sale ? _createElement('div', {
                            'className': 'price-section price-section--withTax non-sale-price--withTax',
                            'key': '5105'
                        }, _createElement('h4', { 'className': 'price price--non-sale' }, this.formatPrice(this.regular_price)), _createElement('span', { 'className': 'sale' }, '\n              -', Math.round((this.regular_price - this.price) / this.regular_price * 100), '%\n            ')) : null), _createElement('div', { 'className': 'card-body-bottom-buttons' }, !this.has_options ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'data-event-type': 'product-click',
                            'data-button-type': 'add-cart',
                            'className': 'button button--' + (this.out_of_stock ? 'disabled' : 'secondary') + ' cm-add-to-cart',
                            'disabled': this.out_of_stock,
                            'key': '5523'
                        }, _createElement('i', { 'className': 'material-icons-outlined' }, 'shopping_cart'), _createElement('span', {}, this.out_of_stock ? Convermax.t('Out of stock') : Convermax.t('Add to Cart'))) : null, this.has_options ? _createElement('a', {
                            'href': this.custom_url,
                            'className': 'button button--secondary cm-choose-option',
                            'key': '6016'
                        }, _createElement('i', { 'className': 'material-icons-outlined' }, 'shopping_cart'), _createElement('span', {}, 'Choose option')) : null, _createElement('label', {
                            'className': 'button button--icon card-figcaption-button compare',
                            'htmlFor': 'compare-' + this.id,
                            'data-tooltip': 'Compare'
                        }, _createElement('input', {
                            'type': 'checkbox',
                            'name': 'products[]',
                            'value': this.id,
                            'id': 'compare-' + this.id,
                            'data-compare-id': this.id,
                            'onChange': window.Convermax.handleProductCompareClick
                        }), _createElement('i', { 'className': 'material-icons-outlined' }, 'compare'), _createElement('span', {}, Convermax.t('Compare'))))))), this.wheel_mpn ? _createElement('button', {
                            'className': 'button button--primary button--small cm_view-on-vehicle-btn',
                            'onClick': () => window.Convermax.openVisualizationDialog(this.wheel_mpn),
                            'key': '6736'
                        }, '\n    View On Vehicle\n  ') : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'ul',
                { 'className': 'productGrid' + (this.view ? ` ${ this.view }` : '') + ' cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '4950'
                        }, '\n  ', Convermax.t('These parts will not fit your'), ' ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n  ', Convermax.t('Parts shown below are based on keyword search only'), '.\n')] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '8020'
                        }, '\n  ', Convermax.t('No vehicle specific products found for'), '\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  ', Convermax.t('Press'), '\n  ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, Convermax.t('here')), '\n  ', Convermax.t('to view parts that fit this vehicle.'), '\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1279'
                    }, _createElement('div', {}, '\n  ', Convermax.t('We failed to process your request'), ' (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ', ', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n  ', _createElement('br', {}), '\n  ', Convermax.t('You may'), '\n  ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, Convermax.t('clear your search')), '\n  ', Convermax.t('and try to search for something else'), '.\n')) : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1828'
                    }, _createElement('div', {}, '\n  ', Convermax.t('Your search for'), '\n  ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\n  ', Convermax.t('did not match any of the products we carry. We searched for'), '\n  ', _createElement('span', { 'className': 'query' }, this.query), ' ', Convermax.t('instead'), '.\n')) : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2192'
                    }, _createElement('div', {}, '\n  ', Convermax.t('Network error'), '\n  ', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n  ', _createElement('br', {}), '\n  ', Convermax.t('We could not reach the search server.'), '\n')) : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2559'
                    }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                        '\n    ',
                        Convermax.t(`This ${ this.pageType } doesn't have any`),
                        '\n    ',
                        !!this.vehicleString ? [
                            '\n      ',
                            Convermax.t('parts that fit'),
                            ' ',
                            _createElement('span', { 'key': '28111' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString ? [' products. '] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30541' }, Convermax.t('No results were found for your search'), '.')] : null, this.query || !!this.vehicleString ? [
                        '\n    ',
                        Convermax.t('Press'),
                        '\n    ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '32121'
                        }, _createElement('b', {}, Convermax.t('here'))),
                        '\n    ',
                        Convermax.t('to clear'),
                        '\n    ',
                        !this.vehicleString ? [
                            ' ',
                            Convermax.t('selection'),
                            '. '
                        ] : null,
                        this.vehicleString && !this.isFacetsSelected ? [
                            '\n      ',
                            Convermax.t('vehicle selection and browse all parts'),
                            '.\n    '
                        ] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3755'
                    }, _createElement('div', {}, '\n  ', Convermax.t('The request took too long. Please'), '\n  ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, Convermax.t('try your search again')), '.\n')) : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '4035'
                    }, _createElement('div', {}, '\n  ', Convermax.t('We were unable to find products that match entire query'), '\n  ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. ', Convermax.t('We performed a partial search instead.'), '\n')) : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '4335'
                    }, _createElement('div', {}, '\n  ', Convermax.t('We failed to process your request'), this.error ? [
                        '\n    (',
                        this.error.status ? [
                            _createElement('b', { 'key': '45180' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '46050' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '46880' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '44814' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n  ', _createElement('br', {}), '\n  ', Convermax.t('Please'), '\n  ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, Convermax.t('try your search again')), '.\n')) : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.pagination(function () {
            function repeatButtons1(buttons, buttonsIndex) {
                return [buttons(function () {
                        return this.template === 'prev' ? _createElement('li', { 'className': 'cmTemplate_prev' }, _createElement('a', {
                            'href': this.pageLink,
                            'className': 'cm_button cm_pagination__button-prev',
                            'aria-label': 'link'
                        }, _createElement('svg', {
                            'aria-hidden': 'true',
                            'focusable': 'false',
                            'role': 'presentation',
                            'className': 'icon icon-chevron-left',
                            'viewBox': '0 0 370.814 370.814'
                        }, _createElement('g', {}, _createElement('g', {}, _createElement('polygon', { 'points': '292.92,24.848 268.781,0 77.895,185.401 268.781,370.814 292.92,345.961 127.638,185.401' })))), '\n      ', Convermax.t('Previous'), '\n    ')) : this.template === 'next' ? _createElement('li', { 'className': 'cmTemplate_next' }, _createElement('a', {
                            'href': this.pageLink,
                            'className': 'cm_button cm_pagination__button-next',
                            'aria-label': 'link'
                        }, '\n      ', Convermax.t('Next'), '\n      ', _createElement('svg', {
                            'aria-hidden': 'true',
                            'focusable': 'false',
                            'role': 'presentation',
                            'className': 'icon icon-chevron-right',
                            'viewBox': '0 0 478.448 478.448'
                        }, _createElement('g', {}, _createElement('g', {}, _createElement('polygon', { 'points': '131.659,0 100.494,32.035 313.804,239.232 100.494,446.373 131.65,478.448 377.954,239.232' })))))) : this.template === 'delimiter' ? _createElement('li', { 'className': 'cm_button cmTemplate_delimiter' }, '...') : this.template === 'page' ? _createElement('li', { 'className': 'cmTemplate_page' }, _createElement('a', {
                            'href': this.pageLink,
                            'className': 'cm_button ' + (this.isActive ? 'cm_pagination__link-current' : 'cm_pagination__link')
                        }, '\n      ', this.page, '\n    ')) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_pagination' }, _createElement.apply(this, [
                'ul',
                { 'className': 'cm_pagination cmRepeater_buttons' },
                _map(this.buttons, repeatButtons1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'compare-link cm_card-compare' }, _createElement('div', {
        'className': 'cm_card-compare_reset button',
        'onClick': window.Convermax.handleCompareResetClick
    }, _createElement('span', { 'className': 'cm_card-compare_reset-text' }, Convermax.t('Reset'))), _createElement('a', {
        'href': '/compare',
        'className': 'cm_card-compare_link button'
    }, '\n      ', Convermax.t('Compare Now'), '\n      ', _createElement('span', { 'className': 'cm_card-compare_count countPill' }))));
}
        export const componentNames = ["cm:message","cm:customMessage","cm:AutoSyncVisualization","cm:SearchVehicleWidget","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:SearchResult","cm:pagination"]